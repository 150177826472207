import React from 'react'
import { Route } from 'react-router-dom'
import AdminLayout from '../layouts/AdminLayout'
import Dashboard from '../pages/Admin/Dashboard/Dashboard'
import ListMerchants from '../pages/Admin/Merchants/ListMerchants'
import ViewMerchant from '../pages/Admin/Merchant/ViewMerchant'
import MerchantSettings from '../pages/Admin/Merchant/MerchantSettings'
import MerchantSettingsAccount from '../pages/Admin/Merchant/Settings/MerchantSettingsAccount'
import MerchantSettingsProcessor from '../pages/Admin/Merchant/Settings/MerchantSettingsProcessor'
import MerchantSettingsUser from '../pages/Admin/Merchant/Settings/MerchantSettingsUser'
import NewProcessor from '../pages/Admin/Processors/NewProcessor'
import AdminNewUser from '../pages/Admin/Users/AdminNewUser'
import AdminImpersonateUser from '../pages/Admin/Users/AdminImpersonateUser'
import NewMerchant from '../pages/Admin/Merchant/NewMerchant'
import ComplianceIndex from '../pages/Admin/Compliance/ComplianceIndex'
import CustomerReport from '../pages/Admin/Compliance/CustomerReport'
import MerchantHome from '../pages/Admin/Merchant/Settings/MerchantHome'
import ListVendors from '../pages/Admin/Vendors/ListVendors'
import ListPayments from '../pages/Admin/Payments/ListPayments'
import ShowPayment from '../pages/Admin/Payments/ShowPayment'
import Financial from '../pages/Admin/Financial/Financial'
import ListTransactions from '../pages/Admin/Transactions/ListTransactions'

export default function AdminRouter() {
  return (
    <Route path="admin" element={<AdminLayout />}>
      <Route path="dashboard" element={<Dashboard />} />
      <Route path="merchants" element={<ListMerchants />}>
        <Route path="new" element={<NewMerchant />} />
      </Route>
      <Route path="transactions" element={<ListTransactions />} />
      <Route path="merchant/:merchantId" element={<ViewMerchant />}>
        <Route path="" element={<MerchantSettings />}>
          <Route path="" element={<MerchantHome />} />
          <Route path="account" element={<MerchantSettingsAccount />} />
          <Route path="processors" element={<MerchantSettingsProcessor />}>
            <Route path="new" element={<NewProcessor />} />
          </Route>
          <Route path="users" element={<MerchantSettingsUser />}>
            <Route path="new" element={<AdminNewUser />} />
            <Route path=":userId/impersonate" element={<AdminImpersonateUser />} />
          </Route>
        </Route>
      </Route>
      <Route path="vendors" element={<ListVendors />} />
      <Route path="payments" element={<ListPayments />} />
      <Route path="financeiro" element={<Financial />} />
      <Route path="payments/:paymentId" element={<ShowPayment />} />
      <Route path="bureau" element={<ComplianceIndex />} />
      <Route path="bureau/:customerId" element={<CustomerReport />} />
    </Route>
  )
}
